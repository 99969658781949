<template>
  <div id="app">
    <div class="container" v-if="isBackVisible">
      <div class="row justify-content-end">
        <div class="col-1 d-flex justify-content-end">
          <router-link to="/" id="back-arrow"><i class="mt-5 mx-3 fa-solid fa-arrow-left"></i></router-link>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "./components/footer"

export default {
  name: "app",
  data() {
    return {
      isBackVisible: false
    }
  },
  components: {
    Footer
  },
  methods: {
    handleBackButton() {
      this.isBackVisible = document.location.pathname.length >= 3
      console.log(this.isBackVisible)
    }
  },
  updated() {
    this.handleBackButton()
  }
}
</script>

<style lang="scss">
@import "./assets/css/main";

html,
body {
  overflow-x: hidden !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
