<template>
  <div id="app" class="mx-4">
    <div
      class="container landing-container vh-100 vh-100 d-flex align-items-center justify-content-center justify-content-md-start">
      <div class="row w-100 px-0 h-100 align-items-center logo-container">
        <div class="col-lg-6 col-12">
          <img class="logo w-100" src="../assets/images/logo-pkl-web-bianco.png">
          <p> Tecnologie RDSL di nuova generazione.
          </p>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row">
        <h1>Soluzioni per la tua azienda</h1>
        <p>Packet Loss Srl è una azienda ICT con esperienza nel campo della progettazione e realizzazione di reti
          wireless, sistemi hardware e
          networking. L'esperienza decennale nel settore delle telecomunicazioni permette di offrire tagli di banda
          personalizzati con MCR & HA elevati.
          Grazie alla formazione e alla competenza acquisita, è in grado di proporre anche servizi WI-FI, Fibra FTTH o
          FTTC, telefonia
          VOIP e intere strutture di telecomunicazioni.
        </p>
        <div class="col-12 my-3 col-md-4">
          <img src="../assets/images/tailor-made.jpg" class="w-100" alt="">
          <div class="card p-2 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Connettività Tailor Made</h5>
              <p class="card-text"> Packet Loss crea pacchetti internet rispettando le richieste del cliente rendendo
                così l'offerta personalizzabile e su misura! </p>

              <router-link to="/tailorMade" href="#" class="btn-custom-card">Scopri di più <i
                  class="fa-solid fa-arrow-right"></i></router-link>
            </div>
          </div>
        </div>
        <div class="col-12 my-3 col-md-4">
          <img src="../assets/images/backup.jpg" class="w-100" alt="">
          <div class="card p-2 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Connessioni di Backup</h5>
              <p class="card-text"> Packet Loss sa cosa significa avere fermi aziendali e si pone come "ruota di scorta"
                per sopperire alle interruzioni di rete! </p>
              <router-link to="/backup" href="#" class="btn-custom-card">Scopri di più <i
                  class="fa-solid fa-arrow-right"></i></router-link>
            </div>
          </div>
        </div>
        <div class="col-12 my-3 col-md-4">
          <img src="../assets/images/intersede.jpg" class="w-100" alt="">
          <div class="card p-2 text-center text-md-start">
            <div class="card-body">
              <h5 class="card-title">Connettività Intersede</h5>
              <p class="card-text"> Packet Loss fornisce link radio dedicati per creare collegamenti L2 fra più sedi
                dislocate nel territorio! </p>
              <router-link to="/intersede" href="#" class="btn-custom-card">Scopri di più <i
                  class="fa-solid fa-arrow-right"></i></router-link>
            </div>
          </div>
        </div>

        <!--        <div class="col-7">-->
        <!--          <p>button</p>-->
        <!--          <p>button</p>-->
        <!--          <p>button</p>-->
        <!--        </div>-->
        <!--        <div class="col-5">image</div>-->
      </div>
    </div>
    <div class="container my-5">
      <div class="row align-items-center">
        <div class="col-12 col-md-4">
          <img class="w-100" src="../assets/images/path12.png" alt="">
        </div>
        <div class="col-12 col-md-5 text-center text-md-start">
          <h1 class="mt-4 mt-md-0">Assistenza</h1>
          <p class="mb-4"> Grazie al sistema di monitoraggio riservato e alla linea diretta <br> tecnico-cliente Packet
            Loss è
            in grado di risolvere guasti rapidamente evitando infinite attese telefoniche, continue escalation e altre
            inutili perdite di tempo. <br> <br>

            Assistenza dal Lunedì al Venerdì dalle 9.00 alle 18.00</p>
          <a href="mailto:info@packetloss.it" class="btn-custom px-4 py-2">Contattaci</a>
        </div>
      </div>
    </div>
    <div class="container py-5 my-5">
      <div class="row text-end">
        <h1>Chi siamo</h1>
        <p>Packet Loss è una realtà bresciana che nasce nel 2011 dalla necessità di fornire banda larga nelle zone
          isolate o difficilmente raggiungibili.
          E' un autonomous system che ha accumulato numerose competenze ed esperienze in un settore in forte crescita ed
          estremamente dinamico,
          creando una rete attuale fondata maggiormente su tecnologia RDSL. Attualmente si estende sulla provincia di
          Brescia, alcune zone di Verona e alcune di Mantova.
          I clienti, tra cui Polizia Locale, Soccorsi Pubblici e Comuni, necessitano di una conessione di qualità ad un
          prezzo accessibile con un ottimo servizio di assistenza.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {}
}
</script>

<style>
.landing-container {
  background-image: url("../assets/images/sfondo.png");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 90%;
  padding: 0 !important;
}

.logo-container {
  background: rgba(15, 16, 21, 0.5) !important;
}

@media only screen and (max-width: 600px) {
  .landing-container {
    background-image: url("../assets/images/sfondo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 300%;
    padding: 0 !important;
  }

  .logo-container {
  background: rgba(15, 16, 21, 0.8) !important;
}
}
</style>
