<template>
  <div class="container-fluid footer-container p-5 my-2 d-flex align-items-end">
    <div class="row w-100">
      <div class="col-12 col-md-4">
        <h3>Contatti</h3>
        
        <p class="mb-0"><b>TELEFONO  </b><a href="tel:+39 0302070157"> +39 030 2070157</a></p>
        <p class="mb-0"><b>CELLULARE </b><a href="tel:+39 3518255086"> +39 351 8255086</a></p>
        <p class="mb-0"><b>WHATSAPP  </b><a href="tel:+39 3518255086"> +39 351 8255086</a></p>
        <p class="mb-0"><b>E-MAIL  </b><a href="mailto:info@packetloss.it"> info@packetloss.it</a></p>
        
      </div>
      <div class="col-12 col-md-4 my-3 my-md-0">
        <h3>Pages</h3>
        <div class="row">
          <router-link to="/">Home</router-link>
          <router-link to="/tailorMade">Connettività Tailor Made</router-link>
          <router-link to="/backup">Connessioni di Backup</router-link>
          <router-link to="/intersede">Connettività Intersede</router-link>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="row text-md-end">
          <h3>Social</h3>
          <div class="row align-items-center mb-5"> 
            <div class="col">
              <a href="https://www.instagram.com/packetloss.srl/"><i class="fa-brands fa-instagram"></i></a>
              <a href="https://www.facebook.com/packet.loss.srl"><i class="px-3 fa-brands fa-facebook"></i></a>
              <a href="https://wa.me/message/UHNU2IU55KTLA1"><i class="fa-brands fa-whatsapp"></i></a>
            </div>
          </div>
          <div class="">
            <p class="semi-hidden">P.IVA 03326360983   N° iscrizione ROC 21694   AS 198800</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer"
}
</script>

<style scoped lang="scss">

.footer-container {
  background: #1f212b !important;
}

a, i, p {
  color: white !important;
}

a {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

h3 {
  color: #6ef0f6;
}

p {
  opacity: .8;
  font-size: .9rem;
}

i {
  font-size: 2rem;
}

.semi-hidden {
  text-align: center;
  opacity: .3;
}
</style>